p.marker-area {
    margin-bottom: 0;
}

p.marker-text-area {
    padding-left: 1.5rem;
    font-size: 14px;
}

.col-3.delivery {
    margin-left: 2.5rem;
}

.icon {
    height: 1.5rem;
    width: 1rem;
    margin-right: .5rem;
}