#footer {
    color: lightgray;
    bottom: 1rem;
    height: 30px;
    font-size: 14px;
    background-color: white;
    width: 100vh;
    margin-top: .5rem;
    display: block;
}

a:link,
a:visited,
a:active {
    color: #7ECDEE,
}

a:hover {
    color: #2AAAE2
}