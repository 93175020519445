input[type="text"] {
    margin: 0;
    padding: 0;
    outline: 0;
    border-width: 0 0 1px;
    border-color: light-gray;
}

input[type="submit"] {
    margin-top: 1rem;
    width: 11rem;
}

form {
    margin-top: 2rem;
}

.floating-label {
    position: relative;
    margin-bottom: 10px;
}

.floating-label label {
    position: absolute;
    top: calc(50%-7px);
    opacity: 0;
    transition: all .3s ease-in-out;
}

.floating-label input:not(:placeholder-shown)+label {
    transform: translateY(-10px);
}

label {
    font-size: 12px;
    opacity: .8;
    font-weight: 400;
}