#main-container {
  margin-top: 2rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}


body {
  position: relative;
  font-family: 'Roboto', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

@media (max-width:992px) {
  #map {
    display: none;
  }
}