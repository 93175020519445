hr {
    margin-top: .25rem;
    margin-bottom: .5rem;
}

tbody>tr>td {
    font-size: 12px;
}

.table-fixed-head {
    overflow-y: auto;
    max-height: 40vh;
}

.table-fixed-head thead th {
    background-color: white;
    position: sticky;
    top: 0;
}

tbody {
    position: inherit;
}

.table-area {
    min-height: 10vh;
}

#table-area {
    margin-top: 2rem;
}